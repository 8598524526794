import React, { useEffect, useState } from 'react';
import { NavHashLink } from 'react-router-hash-link';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import AppLayout from '../../../layouts/app/AppLayout';
import Services from './Services/Services';
import SVGIcons from './images/icons/svgIcons';
import './custom.scss';
import Gallery from './Gallery/gallery';
import Testimonial from './testimonial/testimonial';
import AppHeader from './header';
import AppFooter from './footer';
import { translateThis } from '../../../helpers/language.helper';
import S3ImageViewerWithFallback from '../../../components/awsS3ImageViewer/ImgViewerWithFallback';
import { getFileByKey } from '../../../services/api/aws';
import ContactForm from './contactForm/contactForm';
import { getCurrentFacilityURL } from '../../../helpers/helper.functions';
import IconViewer from '../../../components/iconPicker/icon-viewer';





const TemplatesTwo = ({ facilityData, language = 'en' }) => {
    const websiteData = language === 'es' ? facilityData?.websiteEs : facilityData?.website;

    useEffect(() => {
        setTimeout(() => {
            document.body.classList.add('TemplateTwo');
            document.body.classList.add(`${websiteData?.colorPattern}`);

        }, 50);
        // eslint-disable-next-line
        getFacilityFavIcon()
        // eslint-disable-next-line
    }, []);
    const currentFacilityURL = getCurrentFacilityURL();


    const [openContactForm, setOpenContactForm] = useState(false);
    const [openTourForm, setOpenTourForm] = useState(false);
    const [favicon, setFavicon] = useState('');

    const handleOpenContactForm = () => {
        setOpenContactForm(true);
    };
    const handleCloseContactForm = () => {
        setOpenContactForm(false);
    };
    const handleOpenTourForm = () => {
        setOpenTourForm(true);
    };
    const handleCloseTourForm = () => {
        setOpenTourForm(false);
    };

    const getFacilityFavIcon = () => {
        if (websiteData?.favIcon) {
            getFileByKey(websiteData?.favIcon).then(({ data }) => {
                setFavicon(data?.imgURL || '')
            })
        }

    }


    const programItems = [
        {
            icon: `${websiteData?.workHoursIcon}`,
            title: `${websiteData?.workHoursTitle}`,
            content: `${websiteData?.workHours}`,
        },
        {
            icon: `${websiteData?.workAcceptedAgesIcon}`,
            title: `${websiteData?.workAcceptedAgesTitle}`,
            content: `${websiteData?.workAcceptedAges}`,
        },
        {
            icon: `${websiteData?.workDaysIcon}`,
            title: `${websiteData?.workDaysTitle}`,
            content: `${websiteData?.workDays}`,
        },
        {
            icon: `${websiteData?.workSubsidyProgramsIcon}`,
            title: `${websiteData?.workSubsidyProgramsTitle}`,
            content: `${websiteData?.workSubsidyPrograms}`,
        },

    ];

    const isExternalBannerButton1URL = websiteData?.bannerButton1URL?.startsWith('http');
    const isHashLinkBannerButton1URL = websiteData?.bannerButton1URL?.startsWith('#');
    const isInternalBannerButton1URL = websiteData?.bannerButton1URL?.startsWith('/');

    // custom home functions here
    const pageName = `${websiteData?.websiteTitle}`;
    return (
        <AppLayout
            pageName={pageName}
            favicon={favicon}
            bodyClassName='TemplatesTwo'
            customHeader={<AppHeader facilityData={facilityData} />}
            customFooter={<AppFooter />}
        >
            <div className="main-content-wrapper f-wrp">

                <div className='main-banner-wrp f-wrp'>
                    <div className='bannerBG'>
                        <span className='pcBG'><S3ImageViewerWithFallback key={`file-${websiteData?.bannerImage}`} fileKey={websiteData?.bannerImage} /></span>
                        <span className='mobBG'><S3ImageViewerWithFallback key={`file-${websiteData?.bannerImage}`} fileKey={websiteData?.bannerImage} /></span>
                    </div>
                    <div className='container'>
                        <div className='banner-align f-wrp'>
                            <div className='banner-con-sec f-wrp'>
                                <div className='banner-con-box f-wrp'>
                                    <span className='pc-icon'><img src={facilityData?.facilityLogo?.tempURL} alt='banner icon' /></span>
                                    <span className='mob-icon'><img src={facilityData?.facilityLogo?.tempURL} alt='banner icon' /></span>
                                    <div className='banner-con'>
                                        {websiteData?.bannerTitle && <h1>{websiteData?.bannerTitle}</h1>}
                                        {websiteData?.bannerShortDescription && <p>{websiteData?.bannerShortDescription}</p>}
                                    </div>
                                </div>

                                <>
                                    {websiteData?.bannerButtonName1 ? <>
                                        {isInternalBannerButton1URL ? (
                                            <Button onClick={() => {
                                                window.location.href = `/${currentFacilityURL}${websiteData?.bannerButton1URL}`
                                            }} className='sqr-btn'>{websiteData?.bannerButtonName1}</Button>
                                        ) : isHashLinkBannerButton1URL ? (
                                            <NavHashLink smooth to={`${websiteData?.bannerButton1URL}`} >
                                                <Button className='sqr-btn'>
                                                    {websiteData?.bannerButtonName1}
                                                </Button>
                                            </NavHashLink>
                                        ) : isExternalBannerButton1URL ? (
                                            <Button onClick={() => {
                                                window.open(`${websiteData?.bannerButton1URL}`, '_blank')
                                            }} className='sqr-btn'>{websiteData?.bannerButtonName1}</Button>
                                        ) : <Button className='sqr-btn' onClick={handleOpenContactForm} >{websiteData?.bannerButtonName1}</Button>}
                                    </> : null}
                                </>

                            </div>
                        </div>
                    </div>
                </div>
                {(websiteData?.services?.length > 0) ? <><div className='service-slider-sec f-wrp'>
                    <Services services={websiteData?.services || []} />
                </div>
                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                </> : null}

                {(websiteData?.aboutBlockTitle) ||
                    (websiteData?.aboutBlockShortDescription) ||
                    (websiteData?.aboutBlockDescription) ||
                    (websiteData?.aboutBlockSignature) ||
                    (websiteData?.aboutSideImage) ? <>

                    <div className='about-section-wrp f-wrp'>
                        <div className='container'>
                            <div className='about-grid-wrp f-wrp'>
                                <div className='about-con-sec'>
                                    {websiteData?.aboutBlockTitle && <h1>{websiteData?.aboutBlockTitle}</h1>}
                                    {websiteData?.aboutBlockShortDescription && <p>{websiteData?.aboutBlockShortDescription}</p>}
                                    {websiteData?.aboutBlockDescription && <p>{websiteData?.aboutBlockDescription}</p>}
                                    {websiteData?.aboutBlockSignature && <span>{websiteData?.aboutBlockSignature}</span>}
                                </div>
                                <div className='about-img-sec'>
                                    <span>
                                        <S3ImageViewerWithFallback key={`file-${websiteData?.aboutSideImage}`} fileKey={websiteData?.aboutSideImage} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                </> : null}




                {(websiteData?.contactUsBlockTitle) ||
                    (websiteData?.contactUsBlockShortDescription) ||
                    (websiteData?.contactUsBlockLicenseNumber) ||
                    (websiteData?.contactUsBlockAddress) ||
                    (websiteData?.contactUsBlockEmail) ||
                    (websiteData?.contactUsBlockPhone) ||
                    (websiteData?.contactUsBlockFbUrl) ||
                    (websiteData?.contactUsBlockWhatsappUrl) ||
                    (websiteData?.contactUsImage) ||
                    (websiteData?.contactUsBlockInstagramUrl) ? <>
                    <div className='contact-blk-wrp f-wrp'>
                        <div className='contact-sec-blk f-wrp'>
                            <div className='contact-con'>
                                <div className='f-wrp'>
                                    {websiteData?.contactUsBlockTitle && <h1>{websiteData?.contactUsBlockTitle}</h1>}
                                    {websiteData?.contactUsBlockSubTitle && <h4>{websiteData?.contactUsBlockSubTitle}</h4>}
                                    {websiteData?.contactUsBlockShortDescription && <p>{websiteData?.contactUsBlockShortDescription}</p>}
                                    <ul>
                                        {(websiteData?.contactUsBlockLicenseNumber) && (websiteData?.contactUsBlockAddress) && <li>
                                            <div className='each-contact-block map f-wrp'>
                                                <span className='contact-icon'>
                                                    <span className='iconBG'><SVGIcons.ServiceIconBG /></span>
                                                    <SVGIcons.ContactIconMap />
                                                </span>
                                                <p>{websiteData?.contactUsBlockLicenseNumber ? <>{websiteData?.contactUsBlockLicenseNumber}<br /></> : null}
                                                    {websiteData?.contactUsBlockAddress}</p>
                                            </div>
                                        </li>}
                                        {websiteData?.contactUsBlockEmail && <li>
                                            <div className='each-contact-block mail f-wrp'>
                                                <span className='contact-icon'>
                                                    <span className='iconBG'><SVGIcons.ServiceIconBG /></span>
                                                    <SVGIcons.ContactIconMail />
                                                </span>
                                                <a href={`mailto:${websiteData?.contactUsBlockEmail}`}>
                                                    {websiteData?.contactUsBlockEmail}</a>
                                            </div>
                                        </li>}
                                        {websiteData?.contactUsBlockPhone && <li>
                                            <div className='each-contact-block phone f-wrp'>
                                                <span className='contact-icon'>
                                                    <span className='iconBG'><SVGIcons.ServiceIconBG /></span>
                                                    <SVGIcons.ContactIconPhone />
                                                </span>

                                                <a href={`tel:${websiteData?.contactUsBlockPhone}`}>{websiteData?.contactUsBlockPhone}</a>
                                            </div>
                                        </li>}
                                    </ul>
                                    <Button className='sqr-btn' onClick={handleOpenContactForm} >{websiteData?.contactButtonTitle}</Button>
                                </div>
                            </div>

                            {websiteData?.contactUsImage ? <div className='contact-img'>
                                <span><S3ImageViewerWithFallback key={`file-${websiteData?.contactUsImage}`} fileKey={websiteData?.contactUsImage} /></span>
                            </div> : null}
                        </div>
                    </div>
                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                </> : null}


                {(websiteData?.workBlockTitle) ||
                    (websiteData?.workBlockShortDescription) ||
                    (websiteData?.classItems?.length > 0) ? <>
                    <div className='learning-sec-wrp f-wrp' id={`${websiteData?.workId ? websiteData?.workId : ''}`}>
                        <div className='container'>
                            <div className='learning-sec-header f-wrp'>
                                {(websiteData?.workBlockTitle) && <h1>{websiteData?.workBlockTitle}</h1>}
                                {(websiteData?.workBlockShortDescription) && <p>{websiteData?.workBlockShortDescription}</p>}
                            </div>
                            {(websiteData?.classItems?.length > 0) ? <div className='learning-con-wrp f-wrp'>
                                <div className='learning-grid'>
                                    <div className="row">

                                        {websiteData?.classItems.map((element, key) => (
                                            <div className={`col-lg-4 col-md-6 col-sm-12 col-xs-12 each-class-items`} key={key}>
                                                <div className={`each-learning-sec f-wrp `}>
                                                    <span className='learning-img'>
                                                        <S3ImageViewerWithFallback key={`file-${element?.icon.fileKey}`} fileKey={element?.icon.fileKey} />
                                                    </span>
                                                    <div className='learing-content f-wrp'>
                                                        <span className='learning-icon'>
                                                            <span className='iconBG'><SVGIcons.ServiceIconBG /></span>
                                                            <h3>{'$'}{element.fees}</h3>
                                                            <p>{element.feesPer}</p>
                                                        </span>
                                                        <h4>{element.subTitle}</h4>
                                                        <h3>{element.title}</h3>
                                                        <div className="learning-grid-sec f-wrp">
                                                            <div className="trip-grid">
                                                                <div className="age">
                                                                    <span><b>Age:</b>0-2 years</span>
                                                                </div>
                                                                <div className="days">
                                                                    <span><b>Days:</b>Mon to Fri</span>
                                                                </div>
                                                                <div className="hours">
                                                                    <span><b>Hours:</b>6am - 6pm</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </div> : null}
                        </div>
                    </div>

                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                </> : null}


                {(websiteData?.programItems?.length > 0) ? <>
                    <div className='program-sec-wrp f-wrp'>
                        <div className='program-con-wrp f-wrp'>
                            <div className='container'>
                                <div className='program-grid'>
                                    {programItems.map((element, key) => (
                                        <div className='each-program-sec f-wrp' key={key}>
                                            <span className='program-icon'>
                                                <span className='iconBG'><SVGIcons.ServiceIconBG /></span>
                                                <IconViewer icon={element.icon} />
                                            </span>
                                            <h3>{element.title}</h3>
                                            <p>{element.content}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                </> : null}


                {(websiteData?.activitiesSideImage) ||
                    (websiteData?.programBlockTitle) ||
                    (websiteData?.programBlockShortDescription) ||
                    (websiteData?.programs?.length > 0) ? <>
                    <div className='activities-section-wrp f-wrp'>
                        <div className='container'>
                            <div className='activities-grid-wrp f-wrp'>
                                {websiteData?.activitiesSideImage ? <div className='activities-img-sec'>
                                    <span>
                                        <S3ImageViewerWithFallback key={`file-${websiteData?.activitiesSideImage}`} fileKey={websiteData?.activitiesSideImage} />
                                    </span>
                                </div> : null}
                                <div className='activities-con-sec'>
                                    {(websiteData?.programBlockTitle) && <h1>{websiteData?.programBlockTitle}</h1>}
                                    {(websiteData?.programBlockShortDescription) && <p>{websiteData?.programBlockShortDescription}</p>}
                                    {(websiteData?.programs?.length > 0) ? <ul>
                                        {websiteData?.programs.map((element, key) => (
                                            <li key={key}>
                                                <span className='activities-icon'>
                                                    <span className='iconBG'><SVGIcons.ServiceIconBG /></span>
                                                    <IconViewer icon={element.icon} />
                                                </span>
                                                <p>{element.title}</p></li>
                                        ))}
                                    </ul> : null}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                </> : null}

                {(websiteData?.galleryBlockTitle) ||
                    (websiteData?.galleryImages) ? <>


                    <div className='gallery-section-wrp f-wrp'>
                        <div className='gallery-sec-header f-wrp'>
                            {(websiteData?.galleryBlockTitle) && <h1>{websiteData?.galleryBlockTitle}</h1>}
                        </div>
                        {(websiteData?.galleryImages?.length > 0) ? <Gallery facilityData={websiteData?.galleryImages || []} /> : null}

                        {websiteData?.virtualTourBtnLabel ? <div className='gap theme-band gallery-band f-wrp' style={{ paddingTop: '32.5px', paddingBottom: '32.5px' }}>
                            <Button className='sqr-btn bandBtn' onClick={handleOpenTourForm} >{websiteData?.virtualTourBtnLabel}</Button>
                        </div> : null}
                    </div>

                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                </> : null}


                {(websiteData?.ourTeachersBlockTitle) ||
                    (websiteData?.ourTeachersBlockShortDescription) ||
                    (websiteData?.teachers) ? <>
                    <div className='about-founder-wrp f-wrp'>
                        {(websiteData?.ourTeachersBlockTitle) ||
                            (websiteData?.ourTeachersBlockShortDescription) ? <>
                            <div className='founder-sec-header f-wrp'>
                                <div className='container'>
                                    <div className='gap f-wrp' style={{ paddingTop: '35px' }} />
                                    {(websiteData?.ourTeachersBlockTitle) && <h1>{websiteData?.ourTeachersBlockTitle}</h1>}
                                    {(websiteData?.ourTeachersBlockShortDescription) && <p>{websiteData?.ourTeachersBlockShortDescription}</p>}
                                </div>

                            </div> </> : null}
                        {(websiteData?.teachers) ? <>
                            <div className='founder-con-sec founder2 f-wrp'>
                                <div className='container'>
                                    <div className='row'>

                                        {websiteData?.teachers?.map((element, key) => (
                                            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12" key={key}>
                                                <div className='each-founder-sec f-wrp'>
                                                    <span className='founder-icon'>
                                                        <S3ImageViewerWithFallback key={`file-${element?.image.fileKey}`} fileKey={element?.image.fileKey} />
                                                    </span>
                                                    <h3>{element.title}</h3>
                                                    <span>{element.designation}</span>
                                                    <p>{element.description}</p>
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </> : null}
                    </div>
                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                </> : null}


                {(websiteData?.ourTestimonialBlockTitle) ||
                    (websiteData?.ourTestimonialBlockShortDescription) ||
                    (websiteData?.testimonials?.length > 0) ? <>
                    <div className='testimonial-section-wrp f-wrp' id={`${websiteData?.ourTestimonialBlockId ? websiteData?.ourTestimonialBlockId : ''}`}>
                        <div className='container'>
                            <div className='testimonial-sec-header f-wrp'>
                                <h1>{websiteData?.ourTestimonialBlockTitle}</h1>
                            </div>
                            <Testimonial facilityData={websiteData?.testimonials || []} />
                        </div>
                    </div>

                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                    <div className='gap f-wrp' style={{ paddingTop: '50px' }} /> </> : null}




                {(websiteData?.ourPaymentBlockTitle) ||
                    (websiteData?.ourPaymentBlockDescription) ||
                    (websiteData?.ourPaymentBlockButtonTitle) ? <>
                    <div className={`pay-optiopn-wrp f-wrp ${websiteData?.paymentSideImage ? 'marginTop75' : null}`} id={`${websiteData?.ourPaymentBlockId ? websiteData?.ourPaymentBlockId : ''}`}>
                        <div className='container'>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div className='payment-content-sec f-wrp'>
                                        <h1>{websiteData?.ourPaymentBlockTitle}</h1>
                                        <p>{websiteData?.ourPaymentBlockDescription} </p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">

                                    <div className='payment-button-sec f-wrp'>
                                        {websiteData?.paymentSideImage ? <div className='payment-img'>
                                            <span><S3ImageViewerWithFallback key={`file-${websiteData?.paymentSideImage}`} fileKey={websiteData?.paymentSideImage} /></span>
                                        </div> : null}

                                        {websiteData?.ourPaymentBlockButtonTitle ? <Button onClick={() => {
                                            window.location.href = `/${currentFacilityURL}/my-portal`
                                        }} className='sqr-btn'>{websiteData?.ourPaymentBlockButtonTitle}</Button> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='gap theme-band footer-band f-wrp' style={{ paddingTop: '30px' }} />  </> : null}
            </div>

            <Modal
                className='ppContact-popup'
                open={openContactForm}
                onClose={handleCloseContactForm}
                aria-labelledby="ppContact-popup"
                aria-describedby="ppContact-popup"
            >
                <div className='pp-contact-form f-wrp'>
                    <div className='pp-contact-head'>
                        <p>{translateThis('Contact us')} 💬</p>
                        <Button onClick={handleCloseContactForm}>{SVGIcons.CrossIcon()}</Button>
                    </div>

                    <ContactForm onSuccess={handleCloseContactForm} />
                </div>
            </Modal>

            <Modal
                className='ppContact-popup'
                open={openTourForm}
                onClose={handleCloseTourForm}
                aria-labelledby="ppContact-popup"
                aria-describedby="ppContact-popup"
            >
                <div className='pp-contact-form f-wrp'>
                    <div className='pp-contact-head'>
                        <p>{translateThis('Our installation virtual tour')} 🎥</p>
                        <Button onClick={handleCloseTourForm}>{SVGIcons.CrossIcon()}</Button>
                    </div>

                    <ContactForm onSuccess={handleCloseTourForm} defaultComment='Hello, I’m interested in the virtual tour.' />
                </div>
            </Modal>

        </AppLayout >
    )
}

export default TemplatesTwo;