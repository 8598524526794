import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// @mui
import { Button, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';

// css
import '../../../assets/css/form.scss';

// Components
import { showSnackbar } from '../../../components/snackbar';
import TextInput from '../../../components/inputs/textInput/textInput';

// Services
import ApiCaller from '../../../services/api/general';
import SVGIcons from '../../../assets/images/icons/svgIcons';

import '../home.scss';
import AppIcons from '../../../assets/images/icons';

// ----------------------------------------------------------------------


const PPContactFormLinkES = (props) => {

    useEffect(() => {
        setTimeout(() => {
            document.body.classList.add('contactForm-link');
        }, 50);
    }, []);



    const { onSuccess = () => null } = props;
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        facilityName: '',
        email: '',
        message: `Quiero construir un sitio web para mi centro de cuidado infantil.`
    });

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    }
    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (formData.firstName && formData.lastName && formData.email && formData.message) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {
            email: formData.email,
            name: `${formData?.firstName} ${formData?.lastName}`,
            phoneNumber: formData.phoneNumber,
            subject: formData?.facilityName,
            message: formData?.message
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            const apiCaller = new ApiCaller('inbox/enquiry')
            apiCaller.postData(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success("Gracias por contactarnos!");
                    onSuccess()
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }

    };

    return (
        <>

            <div className='contact-link-wrapper f-wrp'>
                <span className='bannerBG'><img src={AppIcons.bannerBG} alt='banner' /></span>
                <div className='ppContact-popup evolve-contactLink-box'>
                    <div className='pp-contact-form f-wrp'>
                        <div className='pp-contact-head'>
                            <p>🚀 Quiero Mi Sitio Web! 📱 💻</p>
                            <Link to={'/'}><Button>{SVGIcons.CrossIcon()}</Button></Link>
                        </div>
                        {<div>
                            <Stack className='pp-contact-frm standard-form' spacing={1}>
                                <form>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <TextInput
                                                label='Nombre'
                                                name='firstName'
                                                id='firstName'
                                                value={formData.firstName}
                                                translationKey='Nombre'
                                                type='text'
                                                isRequired
                                                placeholder='Nombre'
                                                className='custom-textfield'
                                                getValue={(name, value) => setFormValue(name, value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <TextInput
                                                label='Apellido'
                                                name='lastName'
                                                id='lastName'
                                                value={formData.lastName}
                                                translationKey='Apellido'
                                                type='text'
                                                placeholder='Apellido'
                                                className='custom-textfield'
                                                getValue={(name, value) => setFormValue(name, value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>

                                            <TextInput
                                                label='Teléfono'
                                                name='phoneNumber'
                                                id='phoneNumber'
                                                value={formData.phoneNumber}
                                                translationKey='Teléfono'
                                                type='number'
                                                isRequired
                                                placeholder='Teléfono'
                                                className='custom-textfield'
                                                getValue={(name, value) => setFormValue(name, value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <TextInput
                                                label='Nombre del daycare'
                                                name='facilityName'
                                                id='facilityName'
                                                value={formData.facilityName}
                                                isRequired={false}
                                                translationKey='Nombre del daycare'
                                                type='text'
                                                placeholder='Nombre del daycare'
                                                className='custom-textfield'
                                                getValue={(name, value) => setFormValue(name, value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <TextInput
                                                label='Correo electrónico'
                                                name='email'
                                                id='email'
                                                value={formData.email}
                                                translationKey='E-mail'
                                                type='email'
                                                required
                                                placeholder='Correo electrónico'
                                                className='custom-textfield'
                                                getValue={(name, value) => setFormValue(name, value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <TextField type='text'
                                                defaultValue={formData.message}
                                                className='custom-textfield f-wrp'
                                                name="comments"
                                                label="Comentarios"
                                                multiline
                                                rows={4}
                                                onChange={(e) => setFormValue('message', e.target.value)}
                                            />

                                        </Grid>
                                    </Grid>
                                    <br />
                                    <LoadingButton
                                        loading={isSubmitting}
                                        loadingPosition='start'
                                        startIcon={<></>} // To remove Mui warning
                                        fullWidth
                                        disabled={!isValidForm()}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className='login-btn fill-btn'
                                        onClick={onSubmit}>
                                        {'Enviar'}
                                    </LoadingButton>
                                </form>

                            </Stack>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    );
}

PPContactFormLinkES.propTypes = {
    onSuccess: PropTypes.func
}

export default PPContactFormLinkES;