/* eslint-disable global-require */
const AppIcons = {
  bannerLogo: require('../bannerLogo.png'),
  bannerBG: require('../bannerBG.png'),
  bannerBGMob: require('../bannerBGMob.png'),
  logo: require('../Logo.png'),
  scrollTop: require('../scrollTop.png'),
  scrollBottom: require('../scrollBottom.png'),
  aboutImg: require('../aboutImg.png'),
  installImg: require('../installImg.png'),

  mail: require('./mail.png'),
  mapPoint: require('./mapPoint.png'),
  phone: require('./phone.png'),

  days: require('./days.png'),
  hours: require('./hours.png'),
  ages: require('./ages.png'),
  programs: require('./programs.png'),

  gallery1: require('../gallery/gallery_1.png'),
  gallery2: require('../gallery/gallery_2.png'),
  gallery3: require('../gallery/gallery_3.png'),
  gallery4: require('../gallery/gallery_4.png'),
  gallery5: require('../gallery/gallery_5.png'),
  gallery6: require('../gallery/gallery_6.png'),
  gallery7: require('../gallery/gallery_7.png'),
  gallery8: require('../gallery/gallery_8.png'),
  gallery9: require('../gallery/gallery_9.png'),
  gallery10: require('../gallery/gallery_10.png'),
  gallery11: require('../gallery/gallery_11.png'),
  gallery12: require('../gallery/gallery_12.png'),
  gallery13: require('../gallery/gallery_13.png'),
  gallery14: require('../gallery/gallery_14.png'),
  gallery15: require('../gallery/gallery_15.png'),
  gallery16: require('../gallery/gallery_16.png'),

  fb: require('./fb.png'),
  whatsapp: require('./whatsapp.png'),
  insta: require('./insta.png'),
  
  infoImg: require('../infoImg.png'),

  teachers1: require('../teachers/teachers1.png'),
  teachers2: require('../teachers/teachers2.png'),
  teachers3: require('../teachers/teachers3.png'),

  paymentBG: require('../paymentBG.png'),
  
  
  mihapp: require('../mihapp.png'),
 
  
};
export default AppIcons;
/* eslint-enable global-require */
