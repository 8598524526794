import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import '../home.scss';
import { translateThis } from '../../../helpers/language.helper';
import SVGIcons from '../../../assets/images/icons/svgIcons';


const PrivacyPolicy = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const faqItems = [
        {
            panelCount: 'panel1',
            title: 'policyIntroduction',
            content: <><p>{translateThis('Effective Date: 1/1/2024')}</p>
                <p>{translateThis('policyIntro')}</p>
            </>,
        },
        {
            panelCount: 'panel2',
            title: 'Information We Collect',
            content: <>
                <h4><b>{translateThis('Personal')}</b></h4>
                <p>{translateThis('We collect the following types of information:')}</p>
                <h4><b>{translateThis('Information You Provide:')}</b></h4>
                <ul>
                    <li>{translateThis('Personal details (e.g., name, email address, phone number, and business information) provided when you sign up, contact us, or use our Services.')}</li>
                    <li>{translateThis('Content you upload, including forms, messages, and reports.')}</li>
                    <li>{translateThis('Information about children, entered by parents or guardians, as required for the operation of our Services.')}</li>
                </ul> <br />
                <h4><b>{translateThis('Automatically Collected Information:')}</b></h4>
                <ul>
                    <li>{translateThis('Device and usage data, including IP address, browser type, operating system, and app activity.')}</li>
                    <li>{translateThis('Cookies and similar technologies to track user activity and enhance functionality.')}</li>
                </ul>
                <br />
                <h4><b>{translateThis('Payment Information:')}</b></h4>
                <ul>
                    <li>{translateThis('If you make a payment through our platform, we collect billing details, payment method, and transaction information. Payment processing is managed by third-party providers (e.g., Stripe), and we do not store your full payment details.')}</li>
                </ul>
            </>,
        },
        {
            panelCount: 'panel3',
            title: 'How We Use Your Information',
            content: <>
                <p>{translateThis('We use the information collected for the following purposes:')}</p>
                <ul>
                    <li>{translateThis('To provide, maintain, and improve our Services.')}</li>
                    <li>{translateThis('To personalize your experience and respond to your inquiries.')}</li>
                    <li>{translateThis('To process payments and manage billing.')}</li>
                    <li>{translateThis('To send you updates, promotions, or relevant communications.')}</li>
                    <li>{translateThis('To ensure the security and integrity of our Services.')}</li>
                    <li>{translateThis('To comply with legal obligations.')}</li>
                </ul>
            </>,
        },
        {
            panelCount: 'panel4',
            title: 'Sharing Your Information',
            content: <>
                <p>{translateThis('We do not sell your personal information. However, we may share your data in the following circumstances:')}</p>
                <ul>
                    <li><b>{translateThis('With Service Providers:')}</b> &nbsp; {translateThis('Trusted third-party vendors who assist with hosting, payment processing, analytics, and communication services.')}</li>
                    <li><b>{translateThis('For Legal Compliance:')}</b> &nbsp; {translateThis('When required by law or to protect our rights and interests.')}</li>
                    <li><b>{translateThis('Business Transfers:')}</b> &nbsp; {translateThis('In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new owner.')}</li>

                </ul>
            </>,
        },
        {
            panelCount: 'panel5',
            title: 'Data Security',
            content: <p>{translateThis('We implement industry-standard security measures to protect your data from unauthorized access, alteration, or disclosure. However, no system is completely secure, and we cannot guarantee absolute security.')}</p>,
        },
        {
            panelCount: 'panel6',
            title: 'Your Rights',
            content: <><p>{translateThis('You have the following rights regarding your personal information:')}</p>
                <ul>
                    <li><b>{translateThis('Access and Update:')}</b> &nbsp; {translateThis('You can view and update your information through your account.')}</li>
                    <li><b>{translateThis('Delete Your Data:')}</b> &nbsp; {translateThis('You may request the deletion of your personal information by contacting us.')}</li>
                    <li><b>{translateThis('Opt-Out:')}</b> &nbsp; {translateThis('You can unsubscribe from marketing emails by clicking the unsubscribe link in our emails.')}</li>

                </ul>
            </>,
        },
        {
            panelCount: 'panel7',
            title: 'Data Retention',
            content: <>
                <p>{translateThis('We retain your information as long as necessary to fulfill the purposes outlined in this policy or as required by law.')}</p>
            </>,
        },
        {
            panelCount: 'panel8',
            title: "Third-Party Links",
            content: <p>{translateThis('Our Services may contain links to third-party websites. We are not responsible for the privacy practices of these external sites and encourage you to review their policies.')}</p>,
        },
        {
            panelCount: 'panel9',
            title: "Children's Privacy",
            content: <p>{translateThis('Our Services are designed to assist parents and childcare providers. We collect information about children only when it is provided by parents or guardians, as required for the functionality of the Services. We do not collect personal information directly from children or without parental consent. If you believe there is an issue regarding data collection about a child, please contact us immediately.')}</p>,
        },
        {
            panelCount: 'panel10',
            title: 'Changes to This Privacy Policy',
            content: <p>{translateThis('We may update this policy periodically to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any updates will be posted with the Effective Date at the top.')}</p>,
        },
        {
            panelCount: 'panel11',
            title: 'Contact Us',
            content: <>
                <p>{translateThis('If you have questions or concerns about this Privacy Policy, please contact us at:')}</p>
                <p>{translateThis('Email:')}&nbsp;<Link style={{ color: 'inherit' }} to={'mailto: hellochildcare@mihapp.co'}>{translateThis('hellochildcare@mihapp.co')}</Link> </p>
                <p>{translateThis('Evolve Childcare:')}&nbsp;<Link style={{ color: 'inherit' }} to={'https://www.evolvechildcare.com/'}>{translateThis('www.evolvechildcare.com')}</Link> </p>
                <p>{translateThis('By using our Services, you agree to this Privacy Policy. Thank you for trusting Evolve Childcare with your information.')}</p>
            </>,
        },

    ];

    return (
        <>
            <div className='faq-accordion-wrp f-wrp'>
                {faqItems.map((element, key) => (
                    <Accordion className='each-accordian-sec' expanded={expanded === element.panelCount} onChange={handleChange(element.panelCount)} key={key}>
                        <AccordionSummary
                            // expandIcon={SVGIcons.ChevronDownIcon()}
                            aria-controls={`${element.panelCount}bh-content`}
                            id={`${element.panelCount}bh-header`}
                        >
                            {expanded === element.panelCount ? <span>{SVGIcons.MinusIcon()}</span> : <span>{SVGIcons.PlusIcon()}</span>}
                            <h4>{translateThis(element.title)}</h4>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>{element.content}</div>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </>
    )
}

export default PrivacyPolicy;