import { Navigate, useRoutes } from 'react-router-dom';
import PrivateRoute from './private.route';

// pages
// import HomePage from '../pages/home/home';
import FacilityHomePage from '../pages/home/facilityHome';
import EvolveChildCareHomePage from '../pages/home/evolveChildCareHomePage';
import PPPlatformHomePage from '../pages/home/ppPlatformHomePage';
import LoginPage from '../pages/auth/login';
import RegisterPage from '../pages/auth/register';
import ForgotPasswordPage from '../pages/auth/forgotPassword';
import PasswordResetMailSuccess from '../pages/auth/passwordResetMailSuccess';
import Page404 from '../pages/pageNotFound';
import ContactUsPage from '../pages/contactUs';
import MyPortal from '../pages/myPortal/index';
import PaymentSuccess from '../pages/payment/successPage';
import PdfPreviewPage from '../pages/pdfRender';
import ResetPasswordPage from '../pages/auth/resetPassword';
import TermsAndConditionsEvolve from '../pages/home/termsAndConditionsEvolve';
import PrivacyPolicyEvolve from '../pages/home/privacyPolicyEvolve';
import PPContactFormLinkEN from '../pages/home/PPcontactForm/PPContactFormLinkEN';
import PPContactFormLinkES from '../pages/home/PPcontactForm/PPContactFormLinkES';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = [
    {
      path: '/',
      // element: <HomePage />,
      element: <EvolveChildCareHomePage />,
    },
    {
      path: '/pdf-preview/:formId',
      element: <PdfPreviewPage />,
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicyEvolve />,
    },
    {
      path: '/terms-and-conditions',
      element: <TermsAndConditionsEvolve />,
    },
    {
      path: '/contact-us-en',
      element: <PPContactFormLinkEN />,
    },
    {
      path: '/contact-us-es',
      element: <PPContactFormLinkES />,
    },
    {
      path: 'payment-success',
      element: <PaymentSuccess />
    },
    {
      path: '/:facility',
      children: [
        {
          path: '',
          element: <FacilityHomePage />,
        }, {
          path: 'chumma',
          element: <FacilityHomePage />,
        },
        // {
        //   path: 'evolve',
        //   element: <EvolveChildCareHomePage />,
        // },
        {
          path: 'ppplatform',
          element: <PPPlatformHomePage />,
        },
        {
          path: 'contact-us',
          element: <ContactUsPage />,
        },
        {
          path: 'my-portal',
          element: <PrivateRoute><MyPortal /></PrivateRoute>
        },
        {
          path: 'payment-success',
          element: <PaymentSuccess />
        },
        {
          path: 'auth',
          children: [
            { element: <Navigate to="login" />, index: true },
            { path: 'register', element: <RegisterPage /> },
            { path: 'login', element: <LoginPage /> },
            { path: 'forgot-password', element: <ForgotPasswordPage /> },
            { path: 'reset-password-email-sent', element: <PasswordResetMailSuccess /> },
            { path: 'reset-password', element: <ResetPasswordPage /> }
          ]
        }
      ]

    },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ];

  const privateRoutes = [];

  return useRoutes(routes.concat(privateRoutes));
}
