import React from 'react';
import PropTypes from 'prop-types';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { Navigation, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import './testimonial.scss';
import { translateThis } from "../../../../helpers/language.helper";


function Testimonial({ facilityData }) {

  const extendedFacilityData = [...facilityData, ...facilityData, ...facilityData];

  return (
    <>
      <div className="testimonial-sec-wrp TemplateTwo">
        <div className="f-wrp">
          <Swiper
            modules={[Navigation, Autoplay, Pagination]}
            spaceBetween={10}
            slidesPerView={1}
            className='content-sec-mob'
            navigation
            loop
            autoplay={{
              delay: 7000,
              disableOnInteraction: false,
            }}
            pagination={{
              dynamicBullets: true,
            }}

          >
            {extendedFacilityData.map((element, key) => (
              <SwiperSlide key={key}>
                <div className="each-testimonial-items f-wrp">
                  {element.description}
                  <h2>{translateThis(element.title)}</h2>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

        </div>
      </div>






    </>
  );
}
Testimonial.propTypes = {
  facilityData: PropTypes.any
}
export default Testimonial;