import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';

// css
import '../../../assets/css/form.scss';

// Components
import { showSnackbar } from '../../../components/snackbar';
import TextInput from '../../../components/inputs/textInput/textInput';

// Helpers
import { translateThis } from '../../../helpers/language.helper';

// Services
import ApiCaller from '../../../services/api/general';


// ----------------------------------------------------------------------


const PPContactForm = (props) => {
    const {defaultComment = '', onSuccess = ()=> null} = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        facilityName: '',
        email: '',
        message: `${defaultComment}`
    });

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    }
    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (formData.firstName && formData.lastName && formData.email && formData.message) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {
            email: formData.email,
            name: `${formData?.firstName} ${formData?.lastName}`,
            phoneNumber: formData.phoneNumber,
            subject: formData?.facilityName,
            message: formData?.message
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            const apiCaller = new ApiCaller('inbox/enquiry')
            apiCaller.postData(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success("Thank you for contacting us!");
                    onSuccess()                    
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }

    };

    return (
        <>
            {<div>
                <Stack className='pp-contact-frm standard-form' spacing={1}>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextInput
                                    label={translateThis('Name')}
                                    name='firstName'
                                    id='firstName'
                                    value={formData.firstName}
                                    translationKey='Name'
                                    type='text'
                                    isRequired
                                    placeholder={translateThis('Name')}
                                    className='custom-textfield'
                                    getValue={(name, value) => setFormValue(name, value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextInput
                                    label={translateThis('Last name')}
                                    name='lastName'
                                    id='lastName'
                                    value={formData.lastName}
                                    translationKey='Last name'
                                    type='text'
                                    placeholder={translateThis('Last name')}
                                    className='custom-textfield'
                                    getValue={(name, value) => setFormValue(name, value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>

                                <TextInput
                                    label={translateThis('Phone')}
                                    name='phoneNumber'
                                    id='phoneNumber'
                                    value={formData.phoneNumber}
                                    translationKey='Phone'
                                    type='number'
                                    isRequired
                                    placeholder={translateThis('Phone')}
                                    className='custom-textfield'
                                    getValue={(name, value) => setFormValue(name, value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextInput
                                    label={translateThis('subscribe_form_Question_6')}
                                    name='facilityName'
                                    id='subscribe_form_Question_6'
                                    value={formData.facilityName}
                                    isRequired={false}
                                    translationKey={translateThis('subscribe_form_Question_6')}
                                    type='text'
                                    placeholder={translateThis('subscribe_form_Question_6')}
                                    className='custom-textfield'
                                    getValue={(name, value) => setFormValue(name, value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextInput
                                    label={translateThis('E-mail')}
                                    name='email'
                                    id='subscribe_form_Question_5'
                                    value={formData.email}
                                    translationKey='E-mail'
                                    type='email'
                                    required
                                    placeholder={translateThis('E-mail')}
                                    className='custom-textfield'
                                    getValue={(name, value) => setFormValue(name, value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField type='text'
                                    defaultValue={formData.message}
                                    className='custom-textfield f-wrp'
                                    name="comments"
                                    label="Comments"
                                    multiline
                                    rows={4}
                                    onChange={(e) => setFormValue('message', e.target.value)}
                                />

                            </Grid>
                        </Grid>
                        <br />
                        <LoadingButton
                            loading={isSubmitting}
                            loadingPosition='start'
                            startIcon={<></>} // To remove Mui warning
                            fullWidth
                            disabled={!isValidForm()}
                            size="large"
                            type="submit"
                            variant="contained"
                            className='login-btn fill-btn'
                            onClick={onSubmit}>
                            {translateThis('Send')}
                        </LoadingButton>
                    </form>

                </Stack>
            </div>}
        </>
    );
}

PPContactForm.propTypes = {
    defaultComment: PropTypes.any,
    onSuccess: PropTypes.func
}

export default PPContactForm;