import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import '../home.scss';
import { translateThis } from '../../../helpers/language.helper';
import SVGIcons from '../../../assets/images/icons/svgIcons';

const TermsAndConditions = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const faqItems = [
        {
            panelCount: 'panel1',
            title: 'termsIntroduction',
            content: <><p>{translateThis('Effective Date: 1/1/2024')}</p>
                <p>{translateThis('termsIntro')}</p>
            </>,
        },
        {
            panelCount: 'panel2',
            title: 'Description of Services',
            content: <>
                <p>{translateThis("The PP Platform is a Software as a Service (SaaS) solution designed to support childcare facilities by streamlining their operations and enhancing communication with parents. Features include:")}</p>
                <ul>
                    <li>{translateThis("Digitalized forms.")}</li>
                    <li>{translateThis("Daily communication tools for parents and childcare providers.")}</li>
                    <li>{translateThis("Payment processing and reporting capabilities.")}</li>
                    <li>{translateThis("Attendance tracking and business metrics.")}</li>
                </ul>
            </>,
        },
        {
            panelCount: 'panel3',
            title: 'User Accounts',
            content: <>
                <p><b>{translateThis('Eligibility:')} </b> &nbsp; {translateThis("Users must be authorized childcare providers or parents associated with a childcare facility to create an account on the Platform.")}</p>
                <p><b>{translateThis('Account Security:')} </b> &nbsp; {translateThis("Users are responsible for maintaining the confidentiality of their login credentials. Any activity under your account is your responsibility.")}</p>
                <p><b>{translateThis('Parent Access:')} </b> &nbsp; {translateThis("Parents accessing the Platform through their childcare provider's account are subject to these terms.")}</p>
            </>,
        },
        {
            panelCount: 'panel4',
            title: 'Fees and Payment',
            content: <>
                <p><b>{translateThis('Subscription Plans:')} </b> &nbsp; {translateThis("The Platform offers tiered subscription plans with varying features. Specific pricing details will be outlined during the sign-up process.")}</p>
                <p><b>{translateThis('Payment Processing:')} </b> &nbsp; {translateThis("Payments are processed through third-party providers, and the Company does not store full payment information.")}</p>
                <p><b>{translateThis('Refunds:')} </b> &nbsp; {translateThis("All fees, including build-up fees, monthly subscriptions, and initial deposits, are non-refundable unless explicitly stated otherwise.")}</p>
            </>,
        },
        {
            panelCount: 'panel5',
            title: 'User Responsibilities',
            content: <>
                <p><b>{translateThis('Compliance with Laws:')} </b> &nbsp; {translateThis("Users are responsible for ensuring their use of the Platform complies with all applicable laws and regulations.")}</p>
                <p><b>{translateThis('Accuracy of Data:')} </b> &nbsp; {translateThis("Users must ensure that all information, including forms and records uploaded or completed on the Platform, is accurate and up-to-date.")}</p>
                <p><b>{translateThis('Restrictions:')} </b> &nbsp; {translateThis("Users may not:")}</p>
                <ul>
                    <li>{translateThis("Alter, replicate, or distribute the Platform’s content without explicit permission.")}</li>
                    <li>{translateThis("Use the Platform for unlawful activities.")}</li>
                    <li>{translateThis("Share or misuse sensitive information, including personal data of parents or children.")}</li>
                </ul>
            </>
        },
        {
            panelCount: 'panel6',
            title: 'Data Security and Privacy',
            content: <>
                <p>{translateThis("The Company employs industry-standard security measures to protect user data. However, users are encouraged to maintain their own backups of important information. Please refer to our Privacy Policy for detailed information about data collection and usage.")}</p>,
            </>,
        },
        {
            panelCount: 'panel7',
            title: 'Platform Availability',
            content: <>
                <p>{translateThis("The Company will make reasonable efforts to ensure the availability and functionality of the Platform but does not guarantee uninterrupted access. Scheduled maintenance or unforeseen technical issues may result in temporary downtime.")}</p>
            </>,
        },
        {
            panelCount: 'panel8',
            title: "Intellectual Property",
            content: <>
                <p>{translateThis('The Company retains ownership of all intellectual property related to the Platform, including software, design templates, and associated content. Users retain ownership of the data they upload to the Platform.')}</p>
            </>,
        },
        {
            panelCount: 'panel9',
            title: 'Limitation of Liability',
            content: <>
                <p>{translateThis('The Company is not responsible for:')}</p>
                <ul>
                    <li>{translateThis("Errors or inaccuracies in forms completed or uploaded to the Platform.")}</li>
                    <li>{translateThis("Downtime caused by third-party services integrated into the Platform.")}</li>
                    <li>{translateThis("Loss of data due to system failures or user error.")}</li>
                </ul>
            </>,
        },
        {
            panelCount: 'panel10',
            title: 'Termination',
            content: <>
                <p><b>{translateThis('User-Initiated Termination:')} </b> &nbsp; {translateThis("Users may terminate their subscription by providing written notice to the Company. Fees paid are non-refundable.")}</p>
                <p><b>{translateThis('Company-Initiated Termination:')} </b> &nbsp; {translateThis("The Company reserves the right to suspend or terminate access to the Platform for violations of this Agreement or non-payment of fees.")}</p>
            </>,
        },
        {
            panelCount: 'panel11',
            title: 'Modifications to the Platform',
            content: <>
                <p>{translateThis("The Company may introduce updates, enhancements, or new features to the Platform. Additional fees may apply for certain updates, and users will be notified in advance.")}</p>
            </>,
        },
        {
            panelCount: 'panel12',
            title: 'Governing Law',
            content: <>
                <p>{translateThis("This Agreement is governed by the laws of the jurisdiction where the Company operates. Any disputes arising from this Agreement will be resolved in the courts of that jurisdiction.")}</p>
            </>,
        },
        {
            panelCount: 'panel13',
            title: 'Contact Information',
            content: <>
                <p>{translateThis('For questions or concerns about these Terms and Conditions, please contact us at:')}</p>
                <p>{translateThis('Evolve Childcare')}&nbsp;<Link style={{ color: 'inherit' }} to={'https://www.evolvechildcare.com/'}>{translateThis('www.evolvechildcare.com')}</Link> </p>
                <p>{translateThis('Email:')}&nbsp;<Link style={{ color: 'inherit' }} to={'mailto: hellochildcare@mihapp.co'}>{translateThis('hellochildcare@mihapp.co')}</Link> </p>
                <p>{translateThis('By using the Platform, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.')}</p>
            </>,
        },

    ];

    return (
        <>
            <div className='faq-accordion-wrp f-wrp'>
                {faqItems.map((element, key) => (
                    <Accordion className='each-accordian-sec' expanded={expanded === element.panelCount} onChange={handleChange(element.panelCount)} key={key}>
                        <AccordionSummary
                            // expandIcon={SVGIcons.ChevronDownIcon()}
                            aria-controls={`${element.panelCount}bh-content`}
                            id={`${element.panelCount}bh-header`}
                        >
                            {expanded === element.panelCount ? <span>{SVGIcons.MinusIcon()}</span> : <span>{SVGIcons.PlusIcon()}</span>}
                            <h4>{translateThis(element.title)}</h4>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>{element.content}</div>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </>
    )
}

export default TermsAndConditions;