/* eslint-disable */

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SVGIcons from '../images/icons/svgIcons';

import './gallery.scss';
import S3ImageViewerWithFallback from '../../../../components/awsS3ImageViewer/ImgViewerWithFallback';


function Gallery({ facilityData }) {

  const [slideCount, setSlideCount] = useState('5');
  const [transformedData, setTransformedData] = useState(facilityData);
  useEffect(() => {
    if (facilityData?.length >= 5) {
      setSlideCount('5');
      setTransformedData([...facilityData, ...facilityData, ...facilityData]);
    } else if (facilityData?.length === 4) {
      setSlideCount('4');
    } else if (facilityData?.length === 3) {
      setSlideCount('3');
    } else if (facilityData?.length === 2) {
      setSlideCount('2');
    } else if (facilityData?.length === 1) {
      setSlideCount('1');
    } else {
      setSlideCount('5');
    }
  }, [facilityData]);



  // eslint-disable-next-line react/prop-types
  const CustomPrevButton = ({ onClick }) => (
    <button type='button' className="swiper-button-prev" onClick={onClick}>
      <SVGIcons.SlideLeft />
    </button>
  );
  // eslint-disable-next-line react/prop-types
  const CustomNextButton = ({ onClick }) => (
    <button type='button' className="swiper-button-next" onClick={onClick}>
      <SVGIcons.SlideRight />
    </button>
  );
  const swiperRef = useRef(null);
 

  const [index, setIndex] = useState(-1);
  const [selectedPhoto, setSelectedPhoto] = useState([]);

  return (
    <>
      {facilityData ? <div className="gallery-sec-wrp TemplatesTwo f-wrp">

        <Swiper
          onSwiper={(swiper) => { swiperRef.current = swiper; }}
          modules={[Navigation, Autoplay]}
          spaceBetween={10}
          slidesPerView={3}
          className='gallery-sec gallery-image'
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          loop
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: slideCount,
              spaceBetween: 0,
            },
          }}

        >
          {transformedData.map((i, k) => (
            <SwiperSlide key={`${i.fileKey}-${k}`} >
              <span className='gallery-image' style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectedPhoto(facilityData.map((i) => ({ src: i.fileKey })))
                  setIndex(k)
                }}>
                <S3ImageViewerWithFallback key={`file-${i.fileKey}`} fileKey={i.fileKey} />
              </span>
            </SwiperSlide>
          ))}
        </Swiper>
        <CustomPrevButton onClick={() => swiperRef.current.slidePrev()} />
        <CustomNextButton onClick={() => swiperRef.current.slideNext()} />
      </div> : null}


      {selectedPhoto && <Lightbox
        slides={selectedPhoto}
        render={{
          slide: ({ slide }) => <S3ImageViewerWithFallback isShowRetry key={`file-${slide.src}`} fileKey={slide.src} />,
        }}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        plugins={[Fullscreen,
          Slideshow,
          // Thumbnails,
          Zoom]}
        controller={{ closeOnBackdropClick: 'true' }}
      />}

    </>
  );
}
Gallery.propTypes = {
  facilityData: PropTypes.any
}
export default Gallery;