import React, { useEffect, useState } from 'react';
import {useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import './home.scss';
import './bannerAnimation.scss';
// import { getAuthRoleName } from '../../helpers/auth.helper';
import ApiCaller from '../../services/api/general';
import { myPortalState } from '../../state/reducerAtoms/myPortal.atom';
import { showSnackbar } from '../../components/snackbar/index';
import KathleenTemplate from '../../features/websiteThemes/kathleen/index';
import AngelicaTemplate from '../../features/websiteThemes/angelica';
import FranciscaTemplate from '../../features/websiteThemes/francisca';
import RafaelaTemplate from '../../features/websiteThemes/rafaela';
import ArgentinaTemplate from '../../features/websiteThemes/argentina';
import YreneTemplate from '../../features/websiteThemes/yrene';
import FriendlyFamilyTemplate from '../../features/websiteThemes/friendlyFamily';

import PPPlatformHomePage from './ppPlatformHomePage';
import { appConfigAtomState } from '../../state/reducerAtoms/app.atom';
import TemplatesOne from '../../features/websiteTemplates/templateOne';
import TemplatesTwo from '../../features/websiteTemplates/templateTwo';
import PPParkingPage from './ppParkingPage';







const HomePage = () => {
    const { facility = '' } = useParams();
    const navigator = useNavigate();
    const apiCaller = new ApiCaller('facility')
    const [isLoading, setIsLoading] = useState(true)
    const [facilityData, setFacilityData] = useState({});
    const [configState, setConfigState] = useRecoilState(myPortalState);
    const appStateAs = useRecoilValue(appConfigAtomState);
    const [appState, setAppState] = useState({
        selectedLang: appStateAs?.appStateAs || ''
    })

    /**
    * Invokes when a state modified
    * @returns Fun
    */
    const modifyConfigState = (modifiedObj = {}) => setConfigState((prv) => ({ ...prv, ...{ facilityInfo: { ...prv.facilityInfo, ...modifiedObj } } }));


    useEffect(() => {
        if (facility) {
            getFacilityInfo()
        }
        setAppState(appStateAs);
        setTimeout(() => {
            document.body.classList.add('facility-home');
        }, 500);
        // eslint-disable-next-line
    }, [facility, appStateAs]);

    const getFacilityInfo = () => {
        if (facilityData) {
            // Do something later
        }
        if (configState) {
            apiCaller.getData(facility).then((data) => {
                if (data) {
                    setFacilityData(data)
                    modifyConfigState(data);
                } else {
                    showSnackbar().failure("Requested facility not found");
                    navigator('/')
                }
            }).finally(() => setIsLoading(false))
        }
    }



    /**
     * Gets the facility home based on the template
     */
    const getFacilityHome = () => {
        const selectedLang = appState?.selectedLang;
        const templateMapping = {
            jskidscastle: <FranciscaTemplate key={`FranciscaTemplate-${selectedLang}`} />,
            brumbrum: <RafaelaTemplate key={`RafaelaTemplate-${selectedLang}`} />,
            sweetsunrise: <KathleenTemplate key={`KathleenTemplate-${selectedLang}`} />,
            kairos: <ArgentinaTemplate key={`ArgentinaTemplate-${selectedLang}`} />,
            smartkids: <AngelicaTemplate key={`AngelicaTemplate-${selectedLang}`} />,
            preciousmoment: <YreneTemplate key={`YreneTemplate-${selectedLang}`} />,
            friendlyfamily: <FriendlyFamilyTemplate key={`FriendFamilyTemplates-${selectedLang}`} />,
            ppplatform: <PPPlatformHomePage key={`PPPlatformHomePage-${selectedLang}`} />,
        };
        const facilityURL = facilityData?.facilityURL?.toLowerCase();
        let templateToRender = templateMapping[facilityURL] || null;
        if (!templateToRender) {
            const customTemplateAs = selectedLang === 'es' ? facilityData?.websiteEs?.template  : facilityData?.website?.template;
            const customTemplate = customTemplateAs  || 'parking-template';
            switch (customTemplate) {
                case 'template-1':
                    templateToRender = <TemplatesOne language = {selectedLang} key={`template1-${selectedLang}`} facilityData={facilityData} />
                    break;
                case 'template-2':
                    templateToRender = <TemplatesTwo language = {selectedLang} key={`template2-${selectedLang}`} facilityData={facilityData} />
                    break;
            
                default:
                    templateToRender = <PPParkingPage key={`template1-${selectedLang}`} facilityData={facilityData}/>
                    break;
            }

        }

        return templateToRender;


    }
    return (
       <>{isLoading ? <span>Loading...</span> : getFacilityHome()}</>
    )
}

export default HomePage;