import React from 'react';
import AppIcons from '../images/icons';
import SVGIcons from '../images/icons/svgIcons';

import './footer.scss';
import { translateThis } from '../../../../helpers/language.helper';


export default function AppFooter() {
    return (
        <div>
            <div className="project-main-footer TemplatesTwo f-wrp">
                <div className="container">
                    <div className='copy-foot f-wrp'>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className='dev-blk'>
                                    <p style={{ textAlign: 'center', fontWeight: '400', marginTop: '5px', color: 'var(--white)' }}>{translateThis('Built by')} &nbsp;<i style={{ cursor: 'pointer', display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}><span style={{ textDecoration: "none", fontStyle: 'normal', cursor: 'pointer' }}>mihapp.co</span> &nbsp;<img style={{ display: 'inline-block', width: '25px', maxWidth: '25px', }} src={AppIcons.mihapp} alt="mihapp" /></i></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}