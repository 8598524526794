import React from 'react';
import dayjs from 'dayjs';
import AppLayout from '../../layouts/app/AppLayout';
import TabLayout from '../../components/tabs';
import './home.scss';
import { translateThis } from '../../helpers/language.helper';
import TermsAndConditions from './termsAndConditions/termsAndConditions';
import PPPlatformAppHeader from './ppPlatformHeader';





const TermsAndConditionsEvolve = () => {

    // custom home functions here
    const pageName = "Terms and Conditions";
    const tabNames = [""];
    const tabContents = [
        <div key={'view_Terms'}><TermsAndConditions /></div>,
    ];

    return (

        <AppLayout
            pageName={pageName}
            isLoadFooter={false}
            customHeader={<PPPlatformAppHeader />}
            bodyClassName='privacyPolicy-main-wrp f-wrp'
        >
            <div className='termsAndCondition-wrp f-wrp'>
                <div className='banner-sec-wrp'>
                    <div className='parking-banner-con f-wrp'>
                        <h1>{translateThis('Terms and Conditions')}</h1>
                        <p>{translateThis('Terms and Conditions for Evolve Childcare and The PP Platform')}</p>
                    </div>
                </div>

                <div className='tab-sec-wrapper f-wrp'>
                    <TabLayout
                        tabContents={tabContents}
                        tabNames={tabNames}
                    />
                </div>

                <div className='parking-foot f-wrp'>
                    <div className='container'>
                        <p>© {dayjs().get('year')} MiHapp | Terms </p>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default TermsAndConditionsEvolve;